import type { Context } from '../types/context'
import type { Permissions, Report, SystemVersion } from '../types/api'
import axiosInstance from '@/plugins/axios'

export async function fetchNavigationPermissions(): Promise<Permissions> {
  const response = await axiosInstance.get<Permissions>('/data/navigation')

  return response.data
}

export async function fetchReportList(businessProcessType: string): Promise<Report[]> {
  const response = await axiosInstance.get<Report[]>(
    `/reports/${businessProcessType}`,
  )

  return response.data
}

export async function fetchSystemVersion(): Promise<SystemVersion> {
  const response = await axiosInstance.get<SystemVersion>('/version')

  return response.data
}

export async function fetchContext(): Promise<Context> {
  const response = await axiosInstance.get<Context>('data/context')

  return response.data
}
