import { computed, ref } from 'vue'
import type { Theme } from './types'
import { ThemeColorMap } from './types'
import axiosInstance from '@/plugins/axios'
import { hasOwnProperty } from '@/modules/common/utils/common'
import Logo from '@/assets/img/logo.svg'
import LoginBackground from '@/assets/img/login-bg.svg'

const theme = ref<Theme | null>()

export const logoUrl = computed(() => theme.value?.logo ? theme.value?.logo : Logo)
export const loginBackgroundUrl = computed(() => theme.value?.loginBackground ? theme.value?.loginBackground : LoginBackground)

async function fetchTheme() {
  const result = await axiosInstance.get<Theme>('/data/theme')

  theme.value = result.data
}

function setColors() {
  if (theme.value) {
    Object.entries(ThemeColorMap).forEach(([key, value]) => {
      if (theme.value && hasOwnProperty(theme.value, key) && theme.value[key])
        document.body.style.setProperty(value, theme.value[key] as string)
    })
  }
}

export async function initTheme() {
  await fetchTheme()
  setColors()
}
