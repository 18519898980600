import { ref } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'

const nextRoute = ref<RouteLocationNormalized | null>(null)

export function useMiddleware() {
  return {
    nextRoute,
  }
}
