import type { AxiosError } from 'axios'
import axios from 'axios'
import {
  BASE_SERVER_ERROR_MESSAGE,
  BASE_WARN_MESSAGE,
  authRoutes,
} from './constants'
import { parseServerResponseMessage } from './helpers'
import { addSearchParam } from '@/modules/common/utils/common'
import { showDynamicConfirmModal } from '@/org-components/OrgDynamicConfirmModal/useDynamicConfirmModal'
import showErrorNotify from '@/modules/common/utils/enotify/showErrorNotify'
import type { TypeWarning } from '@/modules/common/utils/enotify/showErrorNotify'
import { i18n } from '@/modules/i18n'
import { logger } from '@/modules/logger'

let unauthorizedHandler: () => Promise<any> | undefined

export async function setUnauthorizedHandler(handler: () => Promise<any>) {
  unauthorizedHandler = handler
}

const axiosInstance = axios.create({
  baseURL: '/',
})

axiosInstance.interceptors.request.use((config: any) => {
  config.headers['Accept-Language'] = i18n.locale
  return config
})

axiosInstance.interceptors.response.use(
  async (response) => {
    let result = response

    if (response.status === 222) {
      const routeKey = await showDynamicConfirmModal({
        title: response.data.message,
        options: response.data.options,
      })

      response.config.url = addSearchParam(response.config.url!, { routeKey })
      result = await axiosInstance(response.config)
    }

    return result
  },
  async (error: AxiosError) => {
    const isNotAuthRoute = authRoutes.every(
      path => !error.response?.config?.url?.includes(path),
    )
    const errorCode = error.response?.status

    let errorMessage = await parseServerResponseMessage(error)
    let errorType: TypeWarning = 'warn'

    if (errorCode === 401 && isNotAuthRoute) {
      await unauthorizedHandler()
      return Promise.reject(error)
    }

    if (errorCode === 500) {
      errorType = 'error'
      errorMessage = errorMessage ?? BASE_SERVER_ERROR_MESSAGE
    }

    if (errorCode !== 401) {
      logger.error(error)

      showErrorNotify({
        error,
        message: errorMessage ?? BASE_WARN_MESSAGE,
        type: errorType,
      })
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
