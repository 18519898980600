export interface Theme {
  logo: string | null
  loginBackground: string | null
  colorTextAccent: string | null
  colorUIPrimary: string | null
  colorUISecondary: string | null
}

export enum ThemeColorMap {
  colorTextAccent = '--color-text-accent',
  colorUIPrimary = '--color-ui-primary',
  colorUISecondary = '--color-ui-secondary',
}
