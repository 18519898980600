import { DEFAULT_LOCALE, LOCALE_LS_KEY, availableLocales } from '../data/constants'
import type { AvailableLocale } from '../types'

export function getAppLocale(): AvailableLocale {
  const localeFromLS = localStorage.getItem(LOCALE_LS_KEY)
  if (localeFromLS)
    return localeFromLS as AvailableLocale

  const navigatorLanguage: AvailableLocale = (window.navigator.language.split('-')[0] as AvailableLocale)
  return availableLocales.includes(navigatorLanguage) ? navigatorLanguage : DEFAULT_LOCALE
}
