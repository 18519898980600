export enum NotificationType {
  success = 'success',
  warn = 'warn',
  error = 'error',
  info = 'info',
}

export interface Notification {
  id: string
  type: NotificationType
  title: string
  desc?: string
  manual?: boolean
}
