import { reactive } from 'vue'
import type {
  AvailableLocale,
  I18nOptions,
  InterpolParamsType,
  LocalizationData,
  MessageType,
  PluralCountType,
  TranslateType,
  UpdateLocaleType,
} from '../types'
import {
  AVAILABLE_LOCALE_OPTIONS,
  DEFAULT_LOCALE,
  LOCALE_LS_KEY,
  NO_TRANSLATION_TEXT,
  SEPARATOR,
  availableLocales,
} from '../data/constants'
import { getAppLocale } from './getAppLocale'
import { pluralize } from '@/modules/common/utils/text'
import { logger } from '@/modules/logger'

let messages: LocalizationData = {}

export function setLocalizationData(data: LocalizationData) {
  messages = data
}

const locale: AvailableLocale = getAppLocale()

export const i18n: I18nOptions = reactive({
  locale,
  messages,
  availableLocales,
  availableLocaleOptions: AVAILABLE_LOCALE_OPTIONS,
})

export const t: TranslateType = (message: MessageType, params?: PluralCountType | InterpolParamsType) => {
  let translatedMessage = messages[message]

  if (!translatedMessage) {
    logger.error(`translation missing for '${message}' key`, {
      tags: {
        locale,
      },
    })

    return NO_TRANSLATION_TEXT
  }

  if (!params)
    /**
     * Просто перевод текста
     * t('path.to.text.id') -> 'перевод'
     */
    return translatedMessage

  if (typeof params === 'number') {
    /**
     * Перевод текста с плюрализацией
     * @Example:
     *  { id: 'перевод | перевода | переводов' }
     * t('path.to.text.id', 1) -> 'перевод'
     * t('path.to.text.id', 2) -> 'перевода'
     * t('path.to.text.id', 10) -> 'переводов'
     */
    return pluralize(params, translatedMessage.split(SEPARATOR))
  }

  const keysParams = Object.keys(params)

  keysParams.forEach((key) => {
    const searchValue = new RegExp(`{${key}}`, 'g')
    const replaceValue = params[key]?.toString()
    translatedMessage = translatedMessage.replace(searchValue, replaceValue)
  })

  if (translatedMessage.split(SEPARATOR).length > 1)
    /**
     * Перевод текста с плюрализацией и интерполяцией
     * @Example:
     *  { id: '{count} перевод | {count} перевода | {count} переводов' }
     * t('path.to.text.id', { count: 1 }) -> '1 перевод'
     * t('path.to.text.id', { count: 4 }) -> '4 перевода'
     * t('path.to.text.id', { count: 10 }) -> '10 переводов'
     */
    return pluralize(Number(params[keysParams[0]]), translatedMessage.split(SEPARATOR))

  /**
   * Перевод текста с интерполяцией
   * @Example:
   *  { id: 'Заявка №{number} «{name}»' }
   * t('path.to.text.id', { number: 1, name: 'имя заявки' }) -> 'Заявка №1 «имя заявки»'
   */
  return translatedMessage
}

export const updateLocale: UpdateLocaleType = (locale: AvailableLocale = DEFAULT_LOCALE) => {
  localStorage.setItem(LOCALE_LS_KEY, locale);
  (document.querySelector('html') as HTMLHtmlElement).setAttribute('lang', locale)
}
