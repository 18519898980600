import { NotificationType, showNotification } from '@/org-components/OrgNotification'
import type { EnotifyOptions } from '@/modules/common/utils/enotify/types'

export default {
  info: (title: string, options?: EnotifyOptions) => {
    showNotification({
      type: NotificationType.info,
      title,
      desc: options?.desc,
    })
  },
  success: (title: string, options?: EnotifyOptions) => {
    showNotification({
      type: NotificationType.success,
      title,
      desc: options?.desc,
    })
  },
  warn: (title: string, options?: EnotifyOptions) => {
    showNotification({
      type: NotificationType.warn,
      title,
      desc: options?.desc,
    })
  },
  error: (title: string, options?: EnotifyOptions) => {
    showNotification({
      type: NotificationType.warn,
      title,
      desc: options?.desc,
    })
  },
}
