import { computed, ref } from 'vue'
import type { Context } from '../types/context'
import { fetchContext } from '@/modules/common/api'

const context = ref<Context | null>(null)

const hasPerformanceAccess = computed(() => !!context.value?.features?.performance)
const hasProductTeamsAccess = computed(() => !!context.value?.features?.productTeams)
const hasProductTeamsPerformance = computed(() => !!context.value?.features?.productTeamsPerformance)
const strategicPlanningFeatures = computed(() => context.value?.features?.strategicPlanning)
const jobDescriptionFeatures = computed(() => context.value?.features?.jobDescription)
const hasRequests = computed(() => !!context.value?.features?.orgChange)

/**
 * Check if addition profession field feature active
 */
const isProfessionFieldActive = computed<boolean>(() => {
  return strategicPlanningFeatures.value
    ? strategicPlanningFeatures.value!.isProfessionFieldActive!
    : false
})

/**
 * Check if weight fixed for strategic planning feature
 */
const isWeightFixed = computed<boolean>(() => {
  return strategicPlanningFeatures.value
    ? strategicPlanningFeatures.value!.isDriverWeightFixed!
    : false
})

/**
 * Check if invest driver feature exist
 */
const isInvestDriverExist = computed<boolean>(() => {
  return strategicPlanningFeatures.value
    ? strategicPlanningFeatures.value!.isInvestDriverExist!
    : false
})

/**
 * Check if import manual drivers from SAP feature active
 */
const isImportManualDriversFromSAP = computed<boolean>(() => {
  return strategicPlanningFeatures.value
    ? strategicPlanningFeatures.value!.isImportManualDriversFromSAP!
    : false
})

/**
 * Check if Strategic Planning feature active
 */
const isStrategicPlanningActive = computed<boolean>(() => {
  return !!strategicPlanningFeatures.value
})

interface SetContextParams {
  context: Partial<Context>
  modify?: boolean
}

function withDefaultSetContextParams(params: Partial<SetContextParams>) {
  const defaultParams: Partial<SetContextParams> = {
    modify: true,
  }

  return {
    ...defaultParams,
    ...params,
  }
}

function setContext(paramsRaw: Partial<SetContextParams>) {
  const params = withDefaultSetContextParams(paramsRaw)

  // if passed modify & context not empty - use base modify mode
  if (params.modify && params.context) {
    context.value = {
      ...context.value,
      ...params.context,
    } as Context
    return
  }

  // else replace context.value on context from args.
  context.value = (params.context as Context)
}

let contextLoadedResolve: undefined | (() => void)

const contextLoaded = new Promise<void>((resolve) => {
  contextLoadedResolve = resolve
})

async function loadContext() {
  const context = await fetchContext()

  setContext({ context })

  contextLoadedResolve?.()
}

export function useContext() {
  return {
    context,
    hasPerformanceAccess,
    hasProductTeamsAccess,
    hasProductTeamsPerformance,
    strategicPlanningFeatures,
    jobDescriptionFeatures,
    hasRequests,
    isProfessionFieldActive,
    isWeightFixed,
    isInvestDriverExist,
    isImportManualDriversFromSAP,
    isStrategicPlanningActive,
    contextLoaded,
    setContext,
    loadContext,
  }
}
