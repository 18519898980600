import type { AxiosError } from 'axios'
import $eNotify from '@/modules/common/utils/enotify'

export type TypeWarning = 'error' | 'warn'
export interface ErrorMap {
  message: string | AxiosError
  type: TypeWarning
}

const map = new Map<string | number, ErrorMap>()

export default ({
  error,
  message,
  type = 'error',
}: {
  error: AxiosError
  message?: string | null
  type?: TypeWarning
}) => {
  const errorId = error.request?.responseURL

  let errorMessage = message

  if (!message) {
    errorMessage
      = ((error?.response?.data as any)?.message as string) ?? error?.message
  }

  if (!map.has(errorId)) {
    setTimeout(() => {
      const currentError = map.get(errorId)

      if (currentError) {
        $eNotify[currentError.type](currentError.message as string)
        map.delete(errorId)
      }
    }, 200)
  }

  map.set(errorId, { type, message: errorMessage as string })
}
