import { getAppLocale, setLocalizationData } from './modules/i18n/helpers'
import { setSentrySettings } from '@/plugins/sentry'
import { initTheme } from '@/plugins/theme'

async function main() {
  try {
    const [localizationResponse] = await Promise.all([
      fetch('/data/localization/current', {
        headers: {
          'Accept-Language': getAppLocale(),
        },
      }),
      initTheme(),
    ])
    const localizationData = await localizationResponse.json()

    setLocalizationData(localizationData)

    const sentrySettingsResponse = await fetch('/data/context/sentry')
    const sentrySettingsData = await sentrySettingsResponse.json()

    setSentrySettings(sentrySettingsData)
  }
  catch (e: any) {
    console.error(e) // на logger заменять не нужно
  }
  finally {
    import('./startApp')
  }
}

main()
