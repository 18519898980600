import { computed, ref } from 'vue'
import { NotificationType } from './types'
import type { Notification } from './types'
import { OrgIconVariant } from '@/org-components/OrgIcon'

const delay = 3000

const defaultIcon = OrgIconVariant.info
const iconsMap = new Map<NotificationType, OrgIconVariant>([
  [NotificationType.info, OrgIconVariant.info],
  [NotificationType.error, OrgIconVariant.validation],
  [NotificationType.warn, OrgIconVariant.error],
  [NotificationType.success, OrgIconVariant.check],
])

const notificationsData = ref<Notification[]>([])

export const notifications = computed<Notification[]>(
  () => notificationsData.value,
)

export function getNotificationIcon(type: NotificationType): OrgIconVariant {
  return iconsMap.get(type) ?? defaultIcon
}

export function showNotification(notification: Omit<Notification, 'id'>) {
  const id = Date.now().toString()
  notificationsData.value.push({
    id,
    ...notification,
  })

  if (!notification.manual)
    setTimeout(() => hideNotification(id), delay)
}

export function hideNotification(id: string) {
  const index = notificationsData.value.findIndex(n => n.id === id)
  notificationsData.value.splice(index, 1)
}
