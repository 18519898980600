export enum OrgIconVariant {
  'add' = 'add',
  'arrowDown' = 'arrowDown',
  'arrowRight' = 'arrowRight',
  'arrowUp' = 'arrowUp',
  'attachment' = 'attachment',
  'back' = 'back',
  'book' = 'book',
  'calendar' = 'calendar',
  'camera' = 'camera',
  'card' = 'card',
  'check' = 'check',
  'checkboxActive' = 'checkboxActive',
  'checkboxDisabledActive' = 'checkboxDisabledActive',
  'checkboxInactive' = 'checkboxInactive',
  'chevronDown' = 'chevronDown',
  'chevronLeft' = 'chevronLeft',
  'chevronRightDouble' = 'chevronRightDouble',
  'chevronUp' = 'chevronUp',
  'clipboard' = 'clipboard',
  'clock' = 'clock',
  'close' = 'close',
  'compare' = 'compare',
  'copy' = 'copy',
  'cross-mini-circle-red' = 'cross-mini-circle-red',
  'cross-mini-circle' = 'cross-mini-circle',
  'delete' = 'delete',
  'depart' = 'depart',
  'dovacancy' = 'dovacancy',
  'download' = 'download',
  'drag' = 'drag',
  'edit' = 'edit',
  'error' = 'error',
  'export' = 'export',
  'eye-menu' = 'eye-menu',
  'face' = 'face',
  'file-check' = 'file-check',
  'file-download' = 'file-download',
  'filter' = 'filter',
  'fingerPlace' = 'fingerPlace',
  'function' = 'function',
  'heartHand' = 'heartHand',
  'history' = 'history',
  'horizontalArrows' = 'horizontalArrows',
  'info' = 'info',
  'instead' = 'instead',
  'loading' = 'loading',
  'lock' = 'lock',
  'menu' = 'menu',
  'money' = 'money',
  'noSee' = 'noSee',
  'optionsHorizontal' = 'optionsHorizontal',
  'paperClip' = 'paperClip',
  'pdf' = 'pdf',
  'plus' = 'plus',
  'printer' = 'printer',
  'radioActive' = 'radioActive',
  'radioInactive' = 'radioInactive',
  'region' = 'region',
  'remove' = 'remove',
  'reorder' = 'reorder',
  'reorganization' = 'reorganization',
  'rocket' = 'rocket',
  'search' = 'search',
  'see' = 'see',
  'send' = 'send',
  'settings-menu' = 'settings-menu',
  'settings' = 'settings',
  'shield' = 'shield',
  'sort' = 'sort',
  'structure' = 'structure',
  'transfer' = 'transfer',
  'trash' = 'trash',
  'umbrella' = 'umbrella',
  'undo' = 'undo',
  'unit' = 'unit',
  'user' = 'user',
  'users' = 'users',
  'vacancy' = 'vacancy',
  'validation' = 'validation',
  'verticalArrows' = 'verticalArrows',
  'en' = 'en',
  'ru' = 'ru',
}
