import { computed, ref } from 'vue'
import type { Me } from '@/modules/auth/types'
import { fetchMe } from '@/modules/auth/api'
import { DEFAULT_LOCALE } from '@/modules/i18n/data/constants'

const user = ref<Me>({
  actions: {
    absence_Absence: false,
    absence_AbsenceEditing: false,
    absence_EmployeesListAccess: false,
    absence_Transactions: false,
    absenceEmployeesListAccess: false,
    admin_AccessManagement: false,
    admin_Admin: false,
    admin_BackgroundJobs: false,
    admin_SystemDiagnostics: false,
    admin_UserManagement: false,
    budgeting_BudgetDashboards: false,
    budgeting_BudgetEntityCatalogs: false,
    budgeting_Budgeting: false,
    budgeting_BudgetList: false,
    budgeting_BudgetReports: false,
    budgeting_BudgetSettings: false,
    budgeting_BudgetStart: false,
    budgeting_UnitBudgetAccess: false,
    changeRequests_AllChangeRequests: false,
    changeRequests_ArchiveOrgItem: false,
    changeRequests_ChangeRequestCreate: false,
    changeRequests_DownloadRequestAccess: false,
    employeesManagement_List: false,
    employeesManagement_Dismissing: false,
    employeesManagement_Recruitment: false,
    entityCatalog_CreateDocument: false,
    entityCatalog_CreateState: false,
    entityCatalog_EditState: false,
    entityCatalog_EntityCatalogAccess: false,
    orgStructure_EmployeeAccess: false,
    orgStructure_HistoryAccess: false,
    orgStructure_OtherUnitsAccess: false,
    orgStructure_PrintAccess: false,
    orgStructure_UnitManagementAccess: false,
    orgStructure_VacancyAccess: false,
    performance2_0_PerformanceCycleEdit: false,
    performance2_0_PerformanceCycleView: false,
    performance2_0_PerformanceMetricEdit: false,
    performance2_0_PerformanceMetricView: false,
    performance2_0_PerformanceChangeRequest: false,
    performance2_0_AllowedToCalculateBonus: false,
    performance2_0_PerformanceAllowedToReport: false,
    productTeams_ProductTeamAssign: false,
    productTeams_ProductTeamManagement: false,
    productTeams_ProductTeamRoleManagement: false,
    changeRequests_DisplayMainMenu: false,
    user_Login: false,
  },
  avatar: null,
  isImpersonating: false,
  name: '',
  roles: [],
  userId: '',
  employees: [],
  language: DEFAULT_LOCALE,
})

const userId = computed(() => user.value.userId)
const employees = computed(() => user.value.employees)
const firstEmployee = computed(() => {
  const [first] = employees.value
  return first || undefined
})

const locale = computed(() => user.value.language)
const userPermissions = computed(() => user.value.actions)

const hasAbsencePerm = computed(() => Boolean(userPermissions.value.absence_Absence))
const hasAbsenceEmployeesListAccessPerm = computed(() => Boolean(userPermissions.value.absence_Absence))
const hasAbsenceEditingPerm = computed(() => Boolean(userPermissions.value.absence_AbsenceEditing))

const hasAdminPerm = computed(() => Boolean(userPermissions.value.admin_Admin))
const hasDiagnosticsPerm = computed(() => Boolean(userPermissions.value.admin_SystemDiagnostics))
const hasBackgroundJobsPerm = computed(() => Boolean(userPermissions.value.admin_BackgroundJobs))

const hasAllChangeRequestsPerm = computed(() => Boolean(userPermissions.value.changeRequests_AllChangeRequests))
const hasArchiveOrgItemPerm = computed(() => Boolean(userPermissions.value.changeRequests_ArchiveOrgItem))
const hasChangeRequestCreatePerm = computed(() => Boolean(userPermissions.value.changeRequests_ChangeRequestCreate))
const hasDownloadRequestAccessPerm = computed(() => Boolean(userPermissions.value.changeRequests_DownloadRequestAccess))

const hasBudgetDashboardsPerm = computed(() => Boolean(userPermissions.value.budgeting_BudgetDashboards))
const hasBudgetEntityCatalogsPerm = computed(() => Boolean(userPermissions.value.budgeting_BudgetEntityCatalogs))
const hasBudgetingPerm = computed(() => Boolean(userPermissions.value.budgeting_Budgeting))
const hasBudgetListPerm = computed(() => Boolean(userPermissions.value.budgeting_BudgetList))
const hasBudgetReportsPerm = computed(() => Boolean(userPermissions.value.budgeting_BudgetReports))
const hasBudgetSettingsPerm = computed(() => Boolean(userPermissions.value.budgeting_BudgetSettings))
const hasBudgetStartPerm = computed(() => Boolean(userPermissions.value.budgeting_BudgetStart))
const hasUnitBudgetAccessPerm = computed(() => Boolean(userPermissions.value.budgeting_UnitBudgetAccess))

const hasEmployeesManagementListAccessPerm = computed(() => Boolean(userPermissions.value.employeesManagement_List))
const hasDismissingPerm = computed(() => Boolean(userPermissions.value.employeesManagement_Dismissing))
const hasRecruitmentPerm = computed(() => Boolean(userPermissions.value.employeesManagement_Recruitment))

const hasEntityCatalogAccessPerm = computed(() => Boolean(userPermissions.value.entityCatalog_EntityCatalogAccess))

const hasOtherUnitsAccessPerm = computed(() => Boolean(userPermissions.value.orgStructure_OtherUnitsAccess))
const hasPrintStructureAccess = computed(() => Boolean(userPermissions.value.orgStructure_PrintAccess))
const hasStructureHistoryAccess = computed(() => Boolean(userPermissions.value.orgStructure_HistoryAccess))

const hasPerformanceMetricViewPerm = computed(() => Boolean(userPermissions.value.performance2_0_PerformanceMetricView))
const hasPerformanceMetricEditPerm = computed(() => Boolean(userPermissions.value.performance2_0_PerformanceMetricEdit))
const hasPerformanceCycleViewPerm = computed(() => Boolean(userPermissions.value.performance2_0_PerformanceCycleView))
const hasPerformanceCycleEditPerm = computed(() => Boolean(userPermissions.value.performance2_0_PerformanceCycleEdit))
const hasPerformanceChangeRequestPerm = computed(() => Boolean(userPermissions.value.performance2_0_PerformanceChangeRequest))
const hasPerformanceCalculateBonusPerm = computed(() => Boolean(userPermissions.value.performance2_0_AllowedToCalculateBonus))
const hasPerformanceViewReportPerm = computed(() => Boolean(userPermissions.value.performance2_0_PerformanceAllowedToReport))

const hasProductTeamAssignPerm = computed(() => Boolean(userPermissions.value.productTeams_ProductTeamAssign))
const hasProductTeamManagementPerm = computed(() => Boolean(userPermissions.value.productTeams_ProductTeamManagement))
const hasProductTeamRoleManagementPerm = computed(() => Boolean(userPermissions.value.productTeams_ProductTeamRoleManagement))
const hasViewChangeRequestsMainMenuPerm = computed(() => Boolean(userPermissions.value.changeRequests_DisplayMainMenu))

let userLoadedResolve: undefined | (() => void)

const userLoaded = new Promise<void>((resolve) => {
  userLoadedResolve = resolve
})

async function loadUser() {
  try {
    user.value = await fetchMe()

    userLoadedResolve?.()
  }
  catch (e: any) {
    // do not user logger here to avoid cycle dependency
    console.error(e)
  }
}

export function useUser() {
  return {
    user,
    userId,
    userPermissions,
    employees,
    firstEmployee,
    locale,
    hasAbsencePerm,
    hasAbsenceEditingPerm,
    hasAbsenceEmployeesListAccessPerm,
    hasAdminPerm,
    hasBackgroundJobsPerm,
    hasAllChangeRequestsPerm,
    hasArchiveOrgItemPerm,
    hasBudgetDashboardsPerm,
    hasBudgetEntityCatalogsPerm,
    hasUnitBudgetAccessPerm,
    hasBudgetListPerm,
    hasBudgetReportsPerm,
    hasBudgetSettingsPerm,
    hasBudgetStartPerm,
    hasBudgetingPerm,
    hasChangeRequestCreatePerm,
    hasDiagnosticsPerm,
    hasEmployeesManagementListAccessPerm,
    hasDismissingPerm,
    hasDownloadRequestAccessPerm,
    hasEntityCatalogAccessPerm,
    hasOtherUnitsAccessPerm,
    hasPrintStructureAccess,
    hasStructureHistoryAccess,
    hasPerformanceMetricViewPerm,
    hasPerformanceMetricEditPerm,
    hasPerformanceCycleViewPerm,
    hasPerformanceCycleEditPerm,
    hasPerformanceChangeRequestPerm,
    hasPerformanceCalculateBonusPerm,
    hasPerformanceViewReportPerm,
    hasProductTeamAssignPerm,
    hasProductTeamManagementPerm,
    hasProductTeamRoleManagementPerm,
    hasRecruitmentPerm,
    hasViewChangeRequestsMainMenuPerm,
    userLoaded,
    loadUser,
  }
}
